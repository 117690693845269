body,
html {
  height: 100%;
}

body {
  color: #606060;
}

.transparent {
  opacity: 0;
}

.transition {
  transition: all 700ms;
}

#app-window {
  background-size: cover;
  background-position: center center;
  overflow: scroll;
}

.visitors-background {
  background-image: url(./images/splashscreen.png);
}

h1, h2, h3 {
  color: #606060;
}

h1 {
  margin-bottom: 20px;
  font-size: 30px;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.hidden {
  display:none;
}

.content {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;

}

#logo-wrapper {
  width: 300px;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

#logo {
  max-width: 100%;
  max-height: 100px;
}

#error {
  border-radius: 25px;
  padding: 20px 20px 20px 20px;
  text-align: center;
}

#confirm {
  border-radius: 25px;
  padding: 20px 20px 30px 20px;
  text-align: center;
}

#branding {
  display: block;
  position: absolute;
  bottom: 20px;
  height: 65px;
}

#branding #brand-label {
  color: #000;
  background-color: #ffffff;
  display: inline-block;
  float: left;
  margin-top: 5px;
  height: 45px;
}

#branding #brand-text {
  margin-left: 12px;
  margin-right: 12px;
  line-height: 45px;
}

#branding #brand-text.not-visible {
  visibility: hidden;
  position: absolute;
}

#branding #brand-image img{
  height: 45px;
}


#branding #info,
#branding #language {
  display: inline-block;
  float: right;
  font-size: 55px;
  cursor: pointer;
}


#branding #info svg,
#branding #language svg {
  float: left;
}

#back {
  position: absolute;
  top: 15px;
  left: 15px;
}

#back a {
  color: white;
  font-size: 20px;
}

#back a:hover {
  color: rgba(255,255,255, 0.7);
  font-size: 20px;
}

#back a .text {
  position: absolute;
  margin-left: 7px;
  top: 2px;
  font-weight: 300;
}

#i-am {
  color: white;
}

.form-control {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  border: 0;
  color: black;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control-admin {
  border: #9cdcdf 2px solid;
}

#phone {
  width: 73%;
}

#phone-code-wrapper {
  width: 25%;
  margin-right: 2%;
  float: left;
  border-radius:30px;
  display:inline-block;
  overflow:hidden;
  background-color: rgba(255, 255, 255, 1);
  border: 0;
}

#phone-code {
  border:0px;
  outline:none;
  text-align: right;
  direction: rtl;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

#phone-code option {
  direction: ltr;
}

#name,
#hostName {
  z-index: 1000;
  position: relative;
}


#guestlist,
#members,
#checked-in-list {
  margin-top: -20px;

}

.content {
  /*
  padding-top: 15px;

  */
  padding-bottom: 40px;
  border-radius: 0 0 20px 20px;

 }



#guestlist .content .item,
#members .content .item,
#checked-in-list .content .item{
  padding: 6px 0 0 20px;
}

.guest-visitor {
  cursor: pointer;
  color: white;
}

.guest-visitor:hover {
  color: rgba(255, 255, 255 ,0.7);

}

.btn-reload {
  font-size: 30px;
  border-radius: 45px;
  line-height: 40px;
  width: 90px;
  border: 0;
}

.btn-reload svg {
  margin-top: -5px;
}


.btn-large {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 40px;
  line-height: 20px;
  min-width: 180px;
  border: 0;
  pointer-events: auto;
}


.btn-medium {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 35px;
  padding: 10px 15px;
  border: 0;
  display: inline-block;
}

.btn-admin {
  background-color: #45bec1;
  color: white;
  border-radius: 35px;
  padding: 10px 15px;
  border: 0;
  display: inline-block;
}

.btn-admin:hover {
  color:white;
  text-decoration: none;
}



.btn-large:hover,
.btn-medium:hover {
  text-decoration: none;
}

.text-shadow {
  text-shadow:  0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1);
}

.changeLanguageBtn {
  cursor: pointer;
  color: #606060;
}

.changeLanguageBtn:hover {
  cursor: pointer;
  color:black;
}

.changeLanguageBtn {
  margin-bottom: 5px;
  border-radius: 20px;
  padding: 5px;
}

.changeLanguageBtn:hover {
  background-color: #eee;
}

.icon {
  margin-right: 10px;
}

.icon img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.settings {
  font-size: 40px;
  color: #a3a3a3;
  cursor: pointer;
}



@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  84% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#home-message-wrapper {
   position: absolute;
   top: 20px;
   text-align: center;

 }

/*
#home-message {
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  opacity: 0;
  -webkit-animation: fadeInOut 6s;
  animation: fadeInOut 6s;
}
*/

#visitor-name {
  margin-bottom: 30px;
}


#home-message {
  display: inline-block;
  -webkit-animation: fadeIn 6s;
  animation: fadeIn 6s;
  padding: 30px 50px;
  border-radius: 45px;
  cursor: pointer;

}

#home-wrapper {
  display: inline-block;
  -webkit-animation: fadeIn 6s;
  animation: fadeIn 6s;
  width: 100%;

}

#loading {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 4px;
  border: 4px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


.full-height {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.terms {
  color: #4f4f4f;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 30px;
}

.form-checkin {
  z-index: 100;
  position: relative;
  pointer-events: none;
}

.long-text {
  margin: 50vh auto 0;
  pointer-events: none;
}

.container-middle {
  position: relative;
}

.popupbox-wrapper {
  max-height: 80%;
}

.popupbox-content {
  overflow: scroll !important;
}

.popupbox-titleBar {
  display: inline-block;
  min-height: 50px;
}

.centered-div {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 35px);
  background-color: #fff;
  padding: 5px 8px 5px 5px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  z-index: 1000;
}

.centered-div img {
  width: 300px;
}

@media only screen and (max-width: 600px) {
  .centered-div {
    transform: translate(-50%, 65px);
  }
}

.badge-text {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  bottom: -29px;
  font-size: 12px;
}

#video-container iframe {
  max-width: 100%;
}




